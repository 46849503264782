import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderActive = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this.loaderActive.asObservable();

  constructor() { }
  showLoader(): void {
    this.loaderActive.next(true);
  }

  hideLoader(): void {
    this.loaderActive.next(false);
  }
}

