<!-- <p>form works!</p> -->
<p class="py-2">If you've purchased a boxed ESET product you can have it activated by entering in your Activation Key below.</p>
<form [formGroup]="productKeyForm">
  <div class="flex-row py-2">
    <label position="stacked" class="block py-2 text-grey-blue">Product Key</label>
    <input type="text" mask="SSS-AAAAA-AAAAA-AAAAA" formControlName="productKey" placeholder="OEM-XXXX... / BOX-XXXX..." clear-input="true" [dropSpecialCharacters]="false" (focus)="productKey.markAsUntouched()" class="uppercase text-xl" [ngClass]="{'border-bright-crimson text-bright-crimson': productKey.errors && productKey.touched}"/>
    <div class="text-bright-crimson py-2 text-sm" [hidden]="!(productKey.errors && productKey.touched)">
      <div id="required" class="error" *ngIf="productKey.errors?.['required']">
        Key is required
      </div>
      <div id="invalid-validation" class="error" *ngIf="productKey.errors?.['invalid']">
        This isn't a valid key, please retype carefully
      </div>
      <div id="min-length" class="error" *ngIf="productKey.errors?.['minlength']">
        This product key is incomplete
      </div>
      <div id="duplicate-key" class="error" *ngIf="productKey.errors?.['serialActivated']">
        This product key is already in use, if this is incorrect please <a href="mailto:renewals@eset.co.za?subject=Serial already activated: {{productKey.value}}">contact us</a>
      </div>
    </div>
  </div>
</form>
<button (click)="processKey()" [disabled]="productKeyForm.invalid"> Next </button>
