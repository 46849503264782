<div *ngIf="client_details$ | async; let client_details">
  <p id="thank_you_message">Thank you {{client_details.client_name}}, please find your license details below.</p>
  <div *ngIf="license_details$ | async; let license_details">
    <div class="container my-4 rounded-md bg-grey-light-concrete p-3 border border-grey-mid">
      <div class="flex justify-between items-center mb-2">
        <div id="product-name">
          <div class="text-lg font-bold">{{license_details.product_name}}</div>
        </div>
        <div id="users" class="mr-1">
          <label class="text-xs">Users</label>
          <div class="font-medium">1</div>
        </div>
        <div id="expires">
          <label class="text-xs">Expires</label>
          <div class="font-medium">{{license_details.license_expiration_date | date:'dd MMM yyyy'}}</div>
        </div>
      </div>
      <div class="flex justify-between align-middle items-center">
        <div id="license-key">
          <label class="text-xs">License Key</label>
          <div class="font-medium">{{license_details.license_licensekey | uppercase}}</div>
        </div>
        <div id="public-id">
          <label class="text-xs">Public ID</label>
          <div class="font-medium">{{license_details.license_plid}}</div>
        </div>
      </div>
    </div>
    <p id="email-directive">You will also have been emailed a license certificate to <strong>{{client_details.client_email}}</strong>.</p>
  </div>
</div>
