<form [formGroup]="clientDetailsForm">
  <div class="flex-row py-2">
    <label position="stacked">Name</label>
    <input
      placeholder="your name"
      clear-input="true"
      formControlName="name"
      (focus)="name.markAsUntouched()"
      [ngClass]="{ 'border-bright-crimson text-bright-crimson': name.errors && name.touched }"
    />
    <div *ngIf="name.errors && name.touched" class="text-bright-crimson py-2 text-sm">
      <div class="error" id="required" *ngIf="name.errors?.['required']">Name is required.</div>
    </div>
  </div>
  <div class="flex-row py-2">
    <label position="stacked">Email</label>
    <input
      placeholder="email@email.com"
      clear-input="true"
      formControlName="email"
      type="email"
      (focus)="email.markAsUntouched()"
      [ngClass]="{ 'border-bright-crimson text-bright-crimson': email.errors && email.touched }"
    />
    <div *ngIf="email.errors && email.touched" class="text-bright-crimson py-2 text-sm">
      <div id="required" class="error" *ngIf="email.errors?.['required']">Email is required</div>
      <div id="invalid" class="error" *ngIf="email.errors?.['email']">This isn't a valid email address</div>
    </div>
  </div>
</form>
<button (click)="activateSerialKey()" [disabled]="clientDetailsForm.invalid">Activate</button>
