import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, map, of, take } from 'rxjs';
import { RegisterFormApiService } from '@core/services/register-form-api.service';
import { RegisterStateService } from '@features/register-form/services/register-state.service';
import { LoaderService } from '@shared/services/loader.service';

@Component({
  selector: 'app-form',
  templateUrl: './serial-key-form.component.html',
})
export class SerialKeyFormComponent implements OnInit {
  productKeyForm = new FormGroup({
    productKey: new FormControl('', { validators: [Validators.required, Validators.minLength(21)], updateOn: 'change' }),
  });

  constructor(private router: Router, private api: RegisterFormApiService, private registerState: RegisterStateService, public loader: LoaderService) {}

  processKey() {
    let serial_key = this.productKeyForm.value.productKey as string;
    this.loader.showLoader();
    if (this.productKeyForm.valid) {
      this.api
        .serialCheck(serial_key)
        .pipe(
          take(1),
          map((response) => {
            this.registerState.setSerialKeyValid(true);
            this.registerState.setSerialKey(serial_key);
            this.router.navigate(['activate']);
            this.loader.hideLoader();
          }),
          catchError((response: any) => {
            this.loader.hideLoader();
            console.log('dad', response.error.errors);
            if (response.error?.errors === `This serial code ${serial_key} is currently active`) {
              this.productKey.setErrors({ serialActivated: true });
            } else {
              this.productKey.setErrors({ invalid: true });
            }
            console.error('error', response.error.errors);
            return of(response);
          })
        )
        .subscribe();
    }
  }

  get productKey() {
    return this.productKeyForm.get('productKey') as FormControl;
  }

  ngOnInit(): void {
    this.registerState.setTitle('Enter Product Key');
  }
}
