import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { RegisterStateService } from '../services/register-state.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerInfoGuard implements CanActivate {
  constructor(private router: Router,private registerState: RegisterStateService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let valid = false;
      this.registerState.getSerialKeyValid().pipe(take(1)).subscribe(resp => {
        valid = resp;
      });
      if(!valid){
        this.router.navigate([''])
      }
    return true;
  }
  
}
