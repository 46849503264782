import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '@core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterFormApiService {
  constructor(private api: Api) {}

  serialCheck(serial_key: string): Observable<any> {
    return this.api.post('v1/serial/verify', {
      serial_code: serial_key,
    });
  }

  serialActivate(serial_key: string, email: string, name: string): Observable<any> {
    return this.api.post('v1/serial/activate', {
      serial_code: serial_key,
      email_address: email,
      client_name: name,
    });
  }
}
