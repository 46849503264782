
<div id="indeterminate-loader" *ngIf="loading$|async" @fadeInFadeOut class="m-0 absolute w-full left-0 top-0">
  <div class="h-2 bg-grey-concrete">
    <div id="loading-bar" class="rounded bg-turquoise-mid h-full w-1/2 absolute -left-1/2"></div>
  </div>
</div>
<div class="header">
  <div class="logo flex items-center -ml-2">
    <img src="https://static.eset.co.za/images/login/eset_logo_2x.png" alt="ESET" width="75" height="38" border="0">
    <span class="text-turquoise-dark p-1 text-sm">Product Registration</span>
    </div>
  <div class="toolbar">
    <h1 class="title text-xl py-2 my-1 font-medium">{{title$ | async}}</h1>
  </div>
  <div class="breadcrumbs flex py-2">
    <div class="breadcrumb p-2 pl-0" [ngClass]="{'text-turquoise-mid': router.url === '/'}">
      <span class="text-sm-start font-medium">Product Key</span>
    </div>
    <div class="breadcrumb p-2 flex items-center" [ngClass]="{'text-turquoise-mid': router.url === '/activate'}">
      <svg class="w-6 h-6 mr-2 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

      <span class="text-sm-start font-medium">Register</span>
    </div>
    <div class="breadcrumb p-2 flex items-center" [ngClass]="{'text-turquoise-mid': router.url === '/thank-you'}">
      <svg class="w-6 h-6 mr-2 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
      <span class="text-sm-start font-medium">Complete</span>
    </div>
  </div>
</div>
