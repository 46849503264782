import { Component, OnInit, } from '@angular/core';
import { animate, style, transition, trigger, } from '@angular/animations';
import { Router } from '@angular/router';
import { RegisterStateService } from '@features/register-form/services/register-state.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInFadeOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router, public loader: LoaderService, private registerState: RegisterStateService) { }
  loading$ = this.loader.loading$;
  title$ = this.registerState.title$;

  ngOnInit(): void {
  }

}
