import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Api {
  url: string;
  apiToken: string;

  constructor(private http: HttpClient) {
    this.url = environment.api.host;
    this.apiToken = environment.api.auth_token;
  }

  get(endpoint: string, params?: any, headers?: any): Observable<any> {
    let requestOptions = this.requestOptions(headers);
    requestOptions = { ...requestOptions, params: params };
    return this.http.get(this.url + '/' + endpoint, requestOptions);
  }

  post(endpoint: string, body: any, headers?: any): Observable<any> {
    const requestOptions = this.requestOptions(headers);
    return this.http.post(this.url + '/' + endpoint, body, requestOptions);
  }

  put(endpoint: string, body: any, headers?: any): Observable<any> {
    const requestOptions = this.requestOptions(headers);
    return this.http.put(this.url + '/' + endpoint, body, requestOptions);
  }

  delete(endpoint: string, headers?: any): Observable<any> {
    const requestOptions = this.requestOptions(headers);
    return this.http.delete(this.url + '/' + endpoint, requestOptions);
  }

  patch(endpoint: string, body: any, headers?: any): Observable<any> {
    const requestOptions = this.requestOptions(headers);
    return this.http.patch(this.url + '/' + endpoint, body, requestOptions);
  }

  requestOptions(options?: any): any {
    let headers = {
      'Content-Type': 'application/json',
    };
    if (this.apiToken) {
      headers = { ...options, Authorization: this.apiToken };
    }
    if (options) {
      headers = { ...options, 'Content-Type': 'application/json' };
    }
    return { headers: new HttpHeaders(headers) };
  }
}
