export const environment = {
  production: true,
  environment: 'staging',
  company_name: 'ESET Southern Africa',
  api: {
    host: 'https://services.eset.co.za',
    port: '443',
    version: 'v1',
    auth_token:
      'eyJhbGciOiJIUzUxMiJ9.eyJhcHBsaWNhdGlvbiI6InJlZ2lzdGVyIiwidG9rZW4iOiJTV3E5ZVNsMk5yUTMzd1lMSzBGSEtHanhkIn0.uiTeVyGChr5klGvaX1We8ycs0YOlf0ESiDXu5xK_9UreKC9AYlmpWufnvVOL6qYSmkUhYlaWFL2dI9U3LBakiw',
  },
  webSocket: {
    heartBeat: {
      location: 'wss://heartbeat.eset.co.za',
      fallback: 'https://api.eset.co.za/status',
      protocol: ['soap', 'xmpp'],
    },
  },
  cookie_scope: '',
  namespace: '.eset.co.za',
  eset_support_email: 'support@eset.co.za',
  eset_partner_email: 'partner@eset.co.za',
  eset_website: 'https://www.eset.com/za',
  passphrase_regex: /((\w){1,}(\ |\-|\,|\.|\_)(\w){1,}){2,}/i,
  uppercase_regex: /[A-Z]{1}/,
  numeric_regex: /\d{1}/,
  xkcd_regex: /(correcthorse|correct\ horse)/i,
  password_min_length: 8,
  passphrase_min_length: 16,
  min_zxcvbn_score: 3,
  static_domain: 'https://static.eset.co.za/',
};
