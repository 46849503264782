import { Component, OnInit } from '@angular/core';
import { RegisterStateService } from '@features/register-form/services/register-state.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
})
export class ThankYouComponent implements OnInit {

  license_details$ = this.registerState.license$;
  client_details$ = this.registerState.client$;

  constructor(private registerState: RegisterStateService) { }

  ngOnInit(): void {
    this.registerState.setTitle('Thank You')
  }

}
