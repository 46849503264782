import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterFormApiService } from '@core/services/register-form-api.service';
import { RegisterStateService } from '@features/register-form/services/register-state.service';
import { LoaderService } from '@shared/services/loader.service';
import { catchError, map, of, take } from 'rxjs';

@Component({
  selector: 'app-customer-info-form',
  templateUrl: './customer-info-form.component.html',
})
export class CustomerInfoFormComponent implements OnInit {
  clientDetailsForm = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(private router: Router, private registerState: RegisterStateService, private api: RegisterFormApiService, public loader: LoaderService) {}

  activateSerialKey() {
    this.loader.showLoader();
    let serial_key = '';
    this.registerState
      .getSerialKey()
      .pipe(take(1))
      .subscribe((resp) => {
        serial_key = resp;
      });
    let name = this.clientDetailsForm.value.name as string;
    let email = this.clientDetailsForm.value.email as string;
    if (this.clientDetailsForm.valid) {
      this.api
        .serialActivate(serial_key, email, name)
        .pipe(
          take(1),
          map((final_response) => {
            if (final_response.active_serial) {
              this.registerState.setLicenseDetails(final_response.active_serial);
              this.registerState.setClientDetails({
                client_email: email,
                client_name: name,
              });
              this.loader.hideLoader();
              this.registerState.setSerialKeyActivated(true);
              console.log('success', 'Serial Key Activated');
              this.router.navigate(['thank-you']);
            }
          }),
          catchError((resp: any) => {
            this.loader.hideLoader();
            if (!!resp.error.errors) {
              console.error('error', resp.error.errors);
            }
            return of(resp);
          })
        )
        .subscribe();
    }
  }

  get name() {
    return this.clientDetailsForm.get('name') as FormControl;
  }

  get email() {
    return this.clientDetailsForm.get('email') as FormControl;
  }

  ngOnInit(): void {
    this.registerState.setTitle('Enter Details');
  }
}
