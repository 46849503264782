import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SerialKeyFormComponent } from './components/serial-key-form/serial-key-form.component';
import { RegisterFormRoutingModule } from './register-form-routing.module';
import { CustomerInfoFormComponent } from './components/customer-info-form/customer-info-form.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';



@NgModule({
  declarations: [
    SerialKeyFormComponent,
    CustomerInfoFormComponent,
    ThankYouComponent
  ],
  imports: [
    CommonModule,
    RegisterFormRoutingModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild()
  ],
  exports: [
  ]
})
export class RegisterFormModule { }
