import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerInfoFormComponent } from './components/customer-info-form/customer-info-form.component';
import { SerialKeyFormComponent } from './components/serial-key-form/serial-key-form.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { CustomerInfoGuard } from './guards/customer-info.guard';
import { ThankYouGuard } from './guards/thank-you.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Enter Product Key',
    component: SerialKeyFormComponent
  },
  {
    path: 'activate',
    title: 'Activate Software',
    component: CustomerInfoFormComponent,
    canActivate: [CustomerInfoGuard]
  },
  {
    path: 'thank-you',
    title: 'Thank You',
    component: ThankYouComponent,
    canActivate: [ThankYouGuard]
  },
  {
    path: '**',
    redirectTo: ''
  },];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterFormRoutingModule {
}
