import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {

  footerDate = new Date().getFullYear()
  companyName = environment.company_name
  constructor() { }

  ngOnInit(): void {
  }

}
