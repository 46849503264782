import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {License} from "../../../shared/models/license";
import {Client} from "../../../shared/models/client";

@Injectable({
  providedIn: 'root'
})
export class RegisterStateService {
  private readonly serialKeyValid = new BehaviorSubject<boolean>(false);
  private readonly serialKeyActivated = new BehaviorSubject<boolean>(false);
  private readonly serialKey = new BehaviorSubject<string>('');
  private readonly _license: BehaviorSubject<License> = new BehaviorSubject<License>({});
  private readonly _client: BehaviorSubject<Client> = new BehaviorSubject<Client>({});
  private readonly _title = new BehaviorSubject<string>('');

  public license$: Observable<License> = this._license.asObservable();
  public client$: Observable<Client> = this._client.asObservable();
  public title$ = this._title.asObservable();

  constructor() { }

  setTitle(title: string) {
    return this._title.next(title);
  }

  setLicenseDetails(license: License): void {
    return this._license.next(license);
  }
  setClientDetails(client: Client): void {
    return this._client.next(client);
  }
  getSerialKey(): Observable<string> {
    return this.serialKey.asObservable();
  }

  setSerialKey(serial_key: string): void {
    return this.serialKey.next(serial_key);
  }

  getSerialKeyValid(): Observable<boolean> {
    return this.serialKeyValid.asObservable();
  }

  setSerialKeyValid(valid: boolean): void {
    this.serialKeyValid.next(valid);
  }

  getSerialKeyActivated(): Observable<boolean> {
    return this.serialKeyActivated.asObservable();
  }

  setSerialKeyActivated(activated: boolean): void {
    this.serialKeyActivated.next(activated);
  }
}
